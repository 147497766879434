import { axiosAuthorized } from "../../api/config"
import { store } from "../../redux/store";
import { saveUser } from "../../redux/userSlice";

export const getDoctors = async () => {
    try {
        const response = await axiosAuthorized.get("/doctor/get_doctor");
        return response;
    } catch (error) {
        throw error;
    }
}


export const addDoctor = async (initialState, department_id, degreeData, specialities, profileImage, coverImage, topTreatments, bestKnowFors, expertises, doctorId) => {
    try {
        const requestData = {
            name: initialState.name,
            age: initialState.age,
            price: initialState.price,
            gender: initialState.gender,
            department_id,
            experience: initialState.experience,
            qualification: degreeData,
            speciality: specialities,
            doctor_image: profileImage,
            doctor_cover_image: coverImage,
            email: initialState.email,
            phone_number: initialState.phone, 
            about_doctor: initialState.about_doctor,
            registration: initialState.registration,
            top_treatments: topTreatments,
            doctor_best_known: bestKnowFors,
            doctor_expert: expertises
        };
        const requestConfig = {
          headers: { "Content-Type": (profileImage instanceof File || coverImage instanceof File) ? "multipart/form-data" : "application/json" },
        };

        const url = `/doctor/update_doctor/${doctorId}`
            

        const response = await axiosAuthorized['put'](url, requestData, requestConfig);
        const user = JSON.stringify(response.data.data);
        localStorage.setItem('doctor', user);
        store.dispatch(saveUser(response.data.data));
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteDoctor = async (doctorId) => {
    try{
        const response = await axiosAuthorized.delete(`/doctor/delete_doctor/${doctorId}`);
        return response;
    } catch (error) {
        throw error;
    }
}