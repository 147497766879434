import React, { useCallback, useEffect, useState } from 'react'
import "./DashboardCard.css"
import { AppointmentCard,TotalPatientCard } from './DashbhoardCardSvg';
import { useSelector } from 'react-redux';
import { getAppointmentsCount } from '../../../utils/appointmentsUtils/appointmentsUtils';
import { getPatientssCount } from '../../../utils/myPatientsUtils/myPatientsUtils';

const DashboardCards = () => {
  const  user  = useSelector((state) => state.userReducer.doctor);
  const [appointments, setAppointments] = useState(0);
  const [patinets, setPatients] = useState(0);
  const setAppointmentsFn = useCallback(() => {
    getAppointmentsCount(user?._id).then(res => setAppointments(0)).catch(err => console.error(err));
  },[user?._id])
  const setPatientsFn = useCallback(() => {
    getPatientssCount(user?._id)
      .then((res) => setPatients(0))
      .catch((err) => console.error(err));
  },[user?._id])
  useEffect(() => {
    setAppointmentsFn();
    setPatientsFn()
  }, [setAppointmentsFn, setPatientsFn]);
  return (
    <div className="dashboardcard-container">
      <AppointmentCard count={appointments} />
      <TotalPatientCard count={patinets} />
    </div>
  );
}

export default DashboardCards