import React, { useCallback, useEffect, useState } from 'react'
import { GoTriangleRight } from 'react-icons/go';
import "./AppointmentRequests.css"
import RequestedUser from './RequestedUser/RequestedUser';
import { useNavigate } from 'react-router-dom';
import { NoDataFound } from '../../../../Common';
import { getRequests, updateAppointment } from '../../../../../utils/onlineAppointmentUtils/onlineAppointmentUtils';

const AppointmentRequests = ({ user }) => {
  const [appointments, setAppointments] = useState([]);
  const setAppointmentRequestsFn = useCallback(() => {
    getRequests(user?._id).then(res => setAppointments(res.data.data)).catch(err => console.log(err));
  }, [user?._id]);
  useEffect(() => setAppointmentRequestsFn(), [setAppointmentRequestsFn]);
  const acceptFn = useCallback((appointmentId) => {
    updateAppointment(appointmentId, "Accepted")
      .then((res) => setAppointmentRequestsFn())
      .catch((err) => console.log(err));
  },[setAppointmentRequestsFn]);
  const rejectFn = useCallback((appointmentId) => {
    updateAppointment(appointmentId, "Rejected")
      .then((res) => setAppointmentRequestsFn())
      .catch((err) => console.log(err));
  },[setAppointmentRequestsFn]);
  const navigate = useNavigate();
  return (
    <div className="appointment-requests-container">
      <div className="flex justify-between items-center">
        <div className="ar-header-text">Appointment Requests</div>
        <div className="ar-header-link">
          <span onClick={() => navigate(`/online-appointment`)}>View All</span>
          <span>
            <GoTriangleRight />
          </span>
        </div>
      </div>
      <div className="ar-users-list">
        {appointments?.length > 0 ? (
          appointments?.map((appointment) => (
            <RequestedUser
              accept={acceptFn}
              reject={rejectFn}
              appointment={appointment}
              key={appointment?._id}
            />
          ))
        ) : (
          <NoDataFound data={"requests"} />
        )}
      </div>
    </div>
  );
}

export default AppointmentRequests