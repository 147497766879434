import React, { useMemo } from "react";
import PatientRow from "./PatientRow";

const RecentPatientsTable = ({ appointments }) => {
  const tableHeadings = useMemo(
    () => ["Patient Name", "P Id", "V Id", "Date", "Time", "Disease"],
    []
  );
  return (
    <div className="rp-table-container">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-blue-100">
                <tr>
                  {tableHeadings.map((heading, index) => (
                    <th scope="col" key={index} className="rp-head-row">
                      {heading}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {appointments?.map((appointment, index) => (
                  <PatientRow key={index} appointment={appointment} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentPatientsTable;
