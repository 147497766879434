import React from 'react'
import StatusButton from './StausButton';

const RequestedUser = ({accept,reject,appointment}) => {
  return (
    <div className="ar-user-container ">
      <div className="ar-user-details-container">
        <div className="ar-user-details-text">
          <span className="text-sm font-semibold">{appointment?.name}</span>
          <span className="text-xs">{`${appointment?.date} ${appointment?.time}`}</span>
        </div>
        <div className="ar-status-buttons">
          <StatusButton accept={accept} reject={reject} appointmentId={appointment?._id} />
        </div>
      </div>
    </div>
  );
}

export default RequestedUser