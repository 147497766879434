import React, { useCallback, useEffect, useState } from 'react'
import MyPatientsTable from '../MyPatientsTable/MyPatientsTable';
import { getTests } from '../../../utils/myPatientsUtils/myPatientsUtils';
import { NoDataFound, SearchInput } from '../../Common';
import { useSelector } from 'react-redux';
import MyPatientsFollowupTable from '../MyPatientsTable/MyPatientsFollowupsTable';
import { getFollowupAppointments } from '../../../utils/appointmentsUtils/appointmentsUtils';

const MyPatientsMainSection = () => {
  const  user  = useSelector((state) => state.userReducer.doctor);
  const [tests, setTests] = useState([]);
  const [followups, setFollowUps] = useState([]);
  const setTestsFn = useCallback(() => {
    getTests(user._id).then(res => setTests(res.data.data)).catch(err => console.log(err));
  }, [user]);
  const setFollowupsFn = useCallback(() => {
    getFollowupAppointments(user._id).then(res => setFollowUps(res.data.data)).catch(err => console.log(err));
  }, [user]);
  useEffect(() => { setTestsFn(); setFollowupsFn(); }, [setFollowupsFn, setTestsFn]);
  const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [mode, setMode] = useState(`tests`);
  useEffect(() => {
    const filteredData = mode === "tests" ? tests?.filter((test) => {
      const nameMatch = test?.test_name?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const pIdMatch = test?.patient_id?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      return nameMatch || pIdMatch;
    }) : followups?.filter((followup) => {
        const nameMatch = followup?.name?.toLowerCase()
            ?.includes(searchQuery.toLowerCase());
        const pIdMatch = followup?.patient_id?.toLowerCase()
            ?.includes(searchQuery.toLowerCase());
        const vIdMatch = followup?.visit_id?.toLowerCase()
            ?.includes(searchQuery.toLowerCase());
        return nameMatch || pIdMatch || vIdMatch;
    })
    setFilteredData(filteredData);
  }, [tests, searchQuery, mode, followups]);
    return (
      <>
        <div className="flex justify-between items-center">
          <div className="flex font-semibold text-lg">My Patients</div>
          <div className="flex">
            <div className="flex  ">
              <SearchInput
                placeholder={"Search by Name, Id"}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </div>
          </div>
        </div>
        <div className="flex  w-full h-full mt-5 justify-between items-center ">
          <div className="flex font-semibold gap-3 ">
            <button
              className={` ${mode === "tests" ? "bg-primary text-white" : "bg-white border-primary text-primary"} border text-sm rounded-md flex gap-2 p-2  items-center`}
              onClick={()=>setMode("tests")}
            >
              <span className="flex gap-1">Tests</span>
            </button>
            <button
              className={` ${mode === "followups" ? "bg-primary text-white" : "bg-white border-primary text-primary"} border text-sm rounded-md flex gap-2 p-2  items-center`}
              onClick={()=>setMode("followups")}
            >
              <span className="flex gap-1">Follow Ups</span>
            </button>
          </div>
        </div>
            {filteredData?.length > 0 ? (
                mode === "tests" ?
                    <MyPatientsTable datas={filteredData} setTestFn={setTestsFn} />
                    :
                    <MyPatientsFollowupTable datas={filteredData} setFollowupFn={setFollowupsFn}/>
        ) : (
          <div className="flex w-full justify-center items-center mt-10">
            <NoDataFound data={mode} />
          </div>
        )}
      </>
    );
}

export default MyPatientsMainSection