import { axiosAuthorized } from "../../api/config";

export const getPatientssCount = async (doctorId) => {
  try {
    const response = await axiosAuthorized.get(`/patientcountsbydoctor/${doctorId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getTests = async (doctorId) => {
    try {
        const response = await axiosAuthorized.get(`/doctortest/${doctorId}`);
        return response;
    } catch (error) {
        return error;
    }
}

export const getLabTests = async () => {
    try {
        const response = await axiosAuthorized.get("/labtest/get_labtest");
        return response;
    } catch (error) {
        throw error;
    }
}

export const getMedicines = async () => {
    try {
      const response = await axiosAuthorized.get("/medPhram/get_medPhram");
        return response;
    } catch (error) {
        throw error;
    }
}

export const getLabCoupons = async () => {
    try {
        let response = await axiosAuthorized.get("/coupon/get_coupon");
        response = response.data.data.filter(item => item.category === "Lab");
        return response;
    } catch (error) {
        throw error;
    }
}

export const addTest = async (initialState,tests,doctorId) => {
    try {
        const data = {
            patient_name: initialState.patient_name,
            test_name: initialState.name,
            patient_id: initialState.patient_id,
            support_info: initialState.supportInfo,
            email: initialState.email,
            payment_method: initialState.payment_method,
            priority: initialState.priority,
            test_reason: initialState.testReason,
            date: initialState.date,
            time: initialState.time,
            reasons: initialState.reasons,
            doctor_id: doctorId,
            tests,
        };
        const requestData = initialState.discount_coupon !== "0" ? { ...data, discount_coupon: initialState.discount_coupon } : { ...data };
        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };
        const response = await axiosAuthorized.post(`/doctor/add_doctortest`, requestData, requestConfig);
        return response;
    } catch (error) {
        return error;
    }
}

export const addPrescription = async (initialState, prescription, description, test, medicine, out_side_service , doctor_id) => {
    try {
        const requestData = {
            ...initialState,
            prescription,
            description,
            doctor_id,
            test,
            out_side_service,
            medicine
      };
        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };
       const [prescriptionResponse, labPrescriptionResponse] = await Promise.all([
        axiosAuthorized.post(`/prescription/add_prescription`, requestData, requestConfig),
        axiosAuthorized.post(`/labprescription/add_labprescription`, requestData, requestConfig)
      ]);
      return { prescriptionResponse, labPrescriptionResponse };
    } catch (error) {
        throw error;
    }
}

export const addDigitalPrescription = async (prescription_upload, id) => {
    try {
        const requestData = {
          prescription_upload,
        };
        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };
      const response = await axiosAuthorized.put(`/update_prescriptionappointment/${id}`, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteTest = async (testId) => {
    try {
        const response = await axiosAuthorized.delete(`/doctor/delete_doctortest/${testId}`);
        return response;
    } catch (error) {
        return error;
    }
}