import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { toast } from "react-toastify";
import FileInput from "../../Common/FormComponents/FileInput/FileInput";
import ShowFile from "../../Common/FormComponents/ShowFile/ShowFile";
import { addDigitalPrescription } from "../../../utils/myPatientsUtils/myPatientsUtils";

const UploadPriscriptionModal = ({
  setUpload,
  setDatasFn,
  upload,
  user,
}) => {
  const [err, setErr] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const [prescription, setPriscription] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!prescription) return setErr({ global: "Please Select Prescription" });
    setErr({ global: "" });
    setLoading(true)
    addDigitalPrescription(prescription, upload?._id)
      .then((res) => {
        toast(`Prescription Uploaded`, {
          type: "success",
          autoClose: 3000,
        });
        setDatasFn();
        setLoading(false);
        setUpload(null);
      })
      .catch((err) => {
        console.error(err);
        toast(`Prescription Upload Failed`, {
          type: "error",
          autoClose: 3000,
        });
      });
  }
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div className="flex bg-white w-full max-w-screen-md overflow-y-auto max-h-screen h-full border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col">
        <div className="flex w-full h-fit items-center justify-between font-semibold">
          <span>{"Upload Prescription"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setUpload(false);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <ShowFile file={prescription} setFile={setPriscription} />
          <FileInput fileName={"Prescription"} setFile={setPriscription} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setUpload(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadPriscriptionModal;
