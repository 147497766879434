
import { axiosAuthorized } from "../../api/config";

export const getAppointmentsCount = async (doctorId) => {
  try {
    const response = await axiosAuthorized.get(`/totalappointmentsbydoctor/${doctorId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getAppointments = async (doctorId, mode='Today') => {
    try {
        let url = `/today_appointment/${doctorId}`;
        if (mode === `Completed`) {
            url = `/today_appointment/${doctorId}`;
        } else if (mode === `New`) {
            url = `/reception/consultant/${doctorId}`
        } 
        const response = await axiosAuthorized.get(url);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getFollowupAppointments = async (doctorId) => {
    try {
        const response = await axiosAuthorized.get(`/doctorfollowsbydoctorid/${doctorId}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const addFollowupAppointment = async (initialState, doctorId) => {
    try {
        const requestData = {
            name: initialState.name,
            patient_id: initialState.patient_id,
            date: initialState.date,
            time: initialState.time,
            reasons: initialState.reasons,
            doctor_id: doctorId
        };

        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };

        const url =`/doctor/add_doctorfollow`;

        const response = await axiosAuthorized['post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}


export const getRecentPatients = async (doctorId) => {
    try {
        const response = await axiosAuthorized.get(`/reception/recent_patient/${doctorId}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getGenderCount = async () => {
    try {
        const response = await axiosAuthorized.get(`/gender_counts`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const addAppointment = async (initialState,doctorId, appointmetnId = null) => {
    try {
        const requestData = {
            name: initialState.name,
            patient_id: initialState.patient_id,
            gender: initialState.gender,
            age: initialState.age,
            disease: initialState.disease,
            date: initialState.date,
            time: initialState.time,
            description: initialState.description,
            status: "Out-Patient",
            doctor_id : doctorId
        };

        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };

        const url = appointmetnId
            ? `/doctor/update_doctorappointment/${appointmetnId}`
            : `/doctor/add_doctorappointment`;

        const response = await axiosAuthorized[appointmetnId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteAppointment = async (appointmetnId) => {
    try {
        const response = await axiosAuthorized.delete(`/doctor/delete_doctorappointment/${appointmetnId}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteFollowupAppointment = async (appointmetnId) => {
    try {
        const response = await axiosAuthorized.delete(`/doctor/delete_doctorfollow/${appointmetnId}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getTodaysAppointments = async (doctorId) => {
    try {
        const response = await axiosAuthorized.get(`/today_appointment/${doctorId}`);
        return response;
    } catch (error) {
        throw error;
    }
}