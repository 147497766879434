export const validateLoginForm = (initialState, setErr) => {

    const idPattern = /^LPH-\d*$/;
    const passwordPattern = /^.{8,}$/
    
    if (!idPattern.test(initialState.doctorId.trim())) {
        setErr({ global: "Invalid Doctor Id" });
        return;
    }
    if (!passwordPattern.test(initialState.password)) {
        setErr({ global: "Invalid Password" });
        return;
    }
    return true;
}